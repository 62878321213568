<script>
import { Notification, NOTIFICATION_STYLES } from '@microbadevs/library'
import moment from 'moment'

import authService from '../../services/authService'
import reportService from '../../services/reportService'
import note from './note.vue'

const defaultSignature =
  'Approved by Prof. Paul Griffin, FRCPA Clinical Microbiologist.'

const states = Object.freeze({
  REPORT_GENERATED: 'REPORT_GENERATED',
  SUMMARY_IN_DRAFT: 'SUMMARY_IN_DRAFT',
  SUMMARY_SUBMITTED: 'SUMMARY_SUBMITTED',
  REPORT_READIED: 'REPORT_READIED',
  REPORT_APPROVED: 'REPORT_APPROVED',
  NOTES_LOCKED: 'NOTES_LOCKED'
})

const badLineBreak = '<p><br></p>'
const badLineBreakPattern = /<p><br><\/p>/g
const goodLineBreak = '<br/>'
const goodLineBreakPattern = /<br\/>/g

// transitions are actions that cause a state change
const transitionFromStates = Object.freeze({
  saveSummaryDraft: ['REPORT_GENERATED', 'SUMMARY_IN_DRAFT'],
  submitSummary: ['REPORT_GENERATED', 'SUMMARY_IN_DRAFT'],
  readyReport: ['SUMMARY_SUBMITTED'],
  approveReport: ['REPORT_READIED'],
  withdrawSummaryBeforeMarkedReady: ['SUMMARY_SUBMITTED'],
  withdrawSummaryBeforeApproval: ['REPORT_READIED'],
  saveClinicalNote: [
    'REPORT_GENERATED',
    'SUMMARY_IN_DRAFT',
    'SUMMARY_SUBMITTED',
    'REPORT_READIED'
  ],
  saveInternalNote: [
    'REPORT_GENERATED',
    'SUMMARY_IN_DRAFT',
    'SUMMARY_SUBMITTED',
    'REPORT_READIED'
  ]
})

const transitionRolePermissions = Object.freeze({
  saveSummaryDraft: [
    'ROLE_STAFF_CAS',
    'ROLE_COBIOME_REPORT_REVIEWER',
    'ROLE_ADMIN'
  ],
  submitSummary: [
    'ROLE_STAFF_CAS',
    'ROLE_COBIOME_REPORT_REVIEWER',
    'ROLE_ADMIN'
  ],
  readyReport: ['ROLE_COBIOME_REPORT_REVIEWER', 'ROLE_ADMIN'],
  approveReport: ['ROLE_COBIOME_REPORT_REVIEWER', 'ROLE_ADMIN'],
  withdrawSummaryBeforeMarkedReady: [
    'ROLE_STAFF_CAS',
    'ROLE_COBIOME_REPORT_REVIEWER',
    'ROLE_ADMIN'
  ],
  withdrawSummaryBeforeApproval: ['ROLE_COBIOME_REPORT_REVIEWER', 'ROLE_ADMIN'],
  saveClinicalNote: ['ROLE_COBIOME_REPORT_REVIEWER', 'ROLE_ADMIN'],
  saveInternalNote: [
    'ROLE_STAFF_CAS',
    'ROLE_COBIOME_REPORT_REVIEWER',
    'ROLE_ADMIN'
  ]
})

// We will refer to other actions that are not transitions as "functionality" (collective noun) for now.
// Functionality itself does not cause a state change, but is checked to conditionally render UI elements
const functionalityFromStates = Object.freeze({
  // Can be thought of as the ability to perform these actions from various states.
  editSummary: ['REPORT_GENERATED', 'SUMMARY_IN_DRAFT'],
  editClinicalNote: [
    'REPORT_GENERATED',
    'SUMMARY_IN_DRAFT',
    'SUMMARY_SUBMITTED',
    'REPORT_READIED'
  ],
  editInternalNote: [
    'REPORT_GENERATED',
    'SUMMARY_IN_DRAFT',
    'SUMMARY_SUBMITTED',
    'REPORT_READIED'
  ]
})

const functionalityRolePermissions = Object.freeze({
  editSummary: ['ROLE_STAFF_CAS', 'ROLE_COBIOME_REPORT_REVIEWER', 'ROLE_ADMIN'],
  editClinicalNote: ['ROLE_COBIOME_REPORT_REVIEWER', 'ROLE_ADMIN'],
  editInternalNote: [
    'ROLE_STAFF_CAS',
    'ROLE_COBIOME_REPORT_REVIEWER',
    'ROLE_ADMIN'
  ]
})

const actionFromStates = Object.freeze({
  ...transitionFromStates,
  ...functionalityFromStates
})

const actionRolePermissions = Object.freeze({
  ...transitionRolePermissions,
  ...functionalityRolePermissions
})

export default {
  name: 'ReportNotes',
  components: { note },
  props: {
    reportId: {
      required: true,
      type: String
    },
    readonly: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      currentUserRoles: authService.getRoles(),

      reportDetails: null,

      currentState: '',
      currentTransition: null, // functions like a loading state, prevents concurrent transitions

      specialistSummaryFieldContent: '',
      clinicalNoteFieldContent: '',
      internalNoteFieldContent: '',
      expertSummary: {
        loading: false,
      },
    }
  },
  computed: {
    reportStatus() {
      return this.getReportStatus(this.reportDetails)
    },

    // Persisted specialist summary
    specialistSummary() {
      return this.getSpecialistSummary(this.reportDetails)
    },
    specialistSummaryLastUpdatedString() {
      return this.getSpecialistSummaryLastUpdatedString(this.reportDetails)
    },

    markedReadyAtString() {
      return this.getMarkedReadyAtString(this.reportDetails)
    },

    // Persisted referral notes
    referralNote() {
      return this.getReferralNote(this.reportDetails)
    },
    referralNoteLastUpdatedString() {
      return this.getReferralNoteLastUpdatedString(this.reportDetails)
    },

    // Persisted clinical notes
    clinicalNote() {
      return this.getClinicalNote(this.reportDetails)
    },
    clinicalNoteLastUpdatedString() {
      return this.getApprovedOrClinicalNoteLastUpdatedString(this.reportDetails)
    },

    // Persisted internal notes that don't appear on the report
    internalNote() {
      return this.getInternalNote(this.reportDetails)
    },
    internalNoteLastUpdatedString() {
      return this.getInternalNoteLastUpdatedString(this.reportDetails)
    }
  },
  mounted() {
    this.fetchInitialReportDetails()
  },
  methods: {
    formatDateUpdatedAt(dateString) {
      const outputFormat = 'Do MMM YYYY, HH:mm:ss'

      return moment
        .utc(dateString)
        .add(moment().utcOffset(), 'm')
        .format(outputFormat)
    },

    getReportStatus(reportDetails) {
      return reportDetails?.reportStatus
    },

    getSpecialistSummary(reportDetails) {
      return reportDetails?.specialistSummary?.summary || ''
    },
    getSpecialistSummaryLastUpdatedString(reportDetails) {
      const specialistSummary = reportDetails?.specialistSummary
      if (!specialistSummary) return 'Not Saved Yet'

      const { updatedAt, updatedBy } = specialistSummary
      if (!(updatedAt && updatedBy)) return 'Not Saved Yet'

      if (this.currentState !== states.SUMMARY_IN_DRAFT)
        return `Submitted ${this.formatDateUpdatedAt(
          updatedAt
        )} by ${updatedBy}`

      return `Draft Saved: ${this.formatDateUpdatedAt(
        updatedAt
      )} by ${updatedBy}`
    },

    getMarkedReadyAtString(reportDetails) {
      if (!reportDetails) return ''

      const approvalReadiedAt = reportDetails.approvalReadiedAt
      const approvalReadiedBy = reportDetails.approvalReadiedBy

      if (!(approvalReadiedAt && approvalReadiedBy)) return ''

      if (
        [states.REPORT_GENERATED, states.SUMMARY_IN_DRAFT].includes(
          this.currentState
        )
      )
        return ''

      return `Marked ready ${this.formatDateUpdatedAt(
        approvalReadiedAt
      )} by ${approvalReadiedBy}`
    },

    getReferralNote(reportDetails) {
      return reportDetails?.referralDetails?.referralNotes
    },
    getReferralNoteLastUpdatedString(reportDetails) {
      const referralDetails = reportDetails?.referralDetails
      if (!referralDetails) return ''

      const { practitionerFullName, referredAt } = referralDetails
      if (!(practitionerFullName && referredAt)) return ''

      return `Referred by ${practitionerFullName} on ${this.formatDateUpdatedAt(
        referredAt
      )}`
    },
    getClinicalNote(reportDetails) {
      return reportDetails?.clinicalNotes || ''
    },
    // has notes ->
    getApprovedOrClinicalNoteLastUpdatedString(reportDetails) {
      if (!reportDetails) return 'Not Saved Yet'

      // Order of these checks is important
      const { recalledAt, recalledBy } = reportDetails
      if (
        this.currentState === states.NOTES_LOCKED &&
        recalledAt &&
        recalledBy
      ) {
        return `Recalled at ${this.formatDateUpdatedAt(
          recalledAt
        )} by ${recalledBy}`
      }

      const { releasedAt, releasedBy } = reportDetails
      if (
        this.currentState === states.NOTES_LOCKED &&
        releasedAt &&
        releasedBy
      ) {
        return `Recalled at ${this.formatDateUpdatedAt(
          recalledAt
        )} by ${recalledBy}`
      }

      const { approvedAt, approvedBy } = reportDetails
      if (
        this.currentState === states.REPORT_APPROVED &&
        approvedAt &&
        approvedBy
      ) {
        return `Approved at ${this.formatDateUpdatedAt(
          approvedAt
        )} by ${approvedBy}`
      }

      const {
        clinicalNotesLastModifiedAt,
        clinicalNotesLastModifiedBy
      } = reportDetails
      if (clinicalNotesLastModifiedAt && clinicalNotesLastModifiedBy)
        return `Draft Saved: ${this.formatDateUpdatedAt(
          clinicalNotesLastModifiedAt
        )} by ${clinicalNotesLastModifiedBy}`

      return 'Not Saved Yet'
    },

    getInternalNote(reportDetails) {
      return reportDetails?.internalNotes || ''
    },
    getInternalNoteLastUpdatedString(reportDetails) {
      if (!reportDetails) return 'Not Saved Yet'

      const internalNotesLastModifiedAt =
        reportDetails.internalNotesLastModifiedAt
      const internalNotesLastModifiedBy =
        reportDetails.internalNotesLastModifiedBy

      if (!(internalNotesLastModifiedAt && internalNotesLastModifiedBy))
        return 'Not Saved Yet'

      return `Last Saved: ${this.formatDateUpdatedAt(
        internalNotesLastModifiedAt
      )} by ${internalNotesLastModifiedBy}`
    },

    hasPermission(action) {
      const allowedRoles = actionRolePermissions[action]

      return (
        allowedRoles &&
        this.currentUserRoles.some((role) => allowedRoles.includes(role))
      )
    },

    isValidFromState(action) {
      return (
        actionFromStates[action] &&
        actionFromStates[action].includes(this.currentState)
      )
    },

    canPerformAction(action) {
      return this.hasPermission(action) && this.isValidFromState(action)
    },

    async fetchInitialReportDetails() {
      try {
        const { data: reportDetails } = await reportService.getReport(
          this.reportId
        )
        this.reportDetails = { ...reportDetails }

        this.specialistSummaryFieldContent = this.fromSavedToEditorFormat(
          this.getSpecialistSummary(this.reportDetails)
        )
        this.clinicalNoteFieldContent = this.fromSavedToEditorFormat(
          this.getClinicalNote(this.reportDetails)
        )
        this.internalNoteFieldContent = this.fromSavedToEditorFormat(
          this.getInternalNote(this.reportDetails)
        )

        this.currentState = this.deriveCurrentState()
      } catch (error) {
        console.error('Error fetching report details:', error)

        Notification({
          title: 'Failed',
          text: error.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },

    async handleTransition(transition) {
      if (this.currentTransition) {
        console.warn('Only one transition can be called at a time.')

        return
      }

      this.currentTransition = transition

      if (!this.hasPermission(transition)) {
        console.warn('You do not have permission to perform this transition.') // bars
        this.currentTransition = null

        return
      }

      if (!this.isValidFromState(transition)) {
        console.warn('Invalid transition for current state.')
        this.currentTransition = null

        return
      }

      const changeset = this.getChangesetBasedOnTransition(transition)
      try {
        const { data: patchedReport } = await reportService.updateReport(
          this.reportId,
          changeset
        )

        this.reportDetails = { ...patchedReport }
        this.currentState = this.deriveCurrentState()
        this.resetFieldsBasedOnTransition(transition)
        this.$emit('reportDetailsChanged')
        this.displaySuccessNotification(transition)
      } catch (error) {
        this.currentState = this.deriveCurrentState()

        console.error('Failed to update report status:', error)

        Notification({
          title: 'Failed',
          text: error.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      } finally {
        this.currentTransition = null
      }
    },

    deriveCurrentState() {
      if (!this.reportDetails) throw Error('Error loading report data.')

      // Valid states
      if (
        this.reportStatus === 'GENERATED' &&
        !this.reportDetails.specialistSummary
      )
        return states.REPORT_GENERATED

      if (
        this.reportStatus === 'GENERATED' &&
        this.reportDetails.specialistSummary?.status === 'SAVED'
      )
        return states.SUMMARY_IN_DRAFT

      if (
        this.reportStatus === 'GENERATED' &&
        this.reportDetails.specialistSummary?.status === 'SUBMITTED'
      )
        return states.SUMMARY_SUBMITTED

      if (
        this.reportStatus === 'READY_FOR_APPROVAL' &&
        this.reportDetails.specialistSummary?.status === 'SUBMITTED'
      )
        return states.REPORT_READIED

      if (
        this.reportStatus === 'APPROVED' &&
        this.reportDetails.specialistSummary?.status === 'SUBMITTED'
      )
        return states.REPORT_APPROVED

      if (this.reportStatus === 'RELEASED' || this.reportStatus === 'RECALLED')
        return states.NOTES_LOCKED

      // Invalid states
      throw Error('Invalid state detected')
    },

    fillMissingSpecialistSummaryObject(reportDetails) {
      if (!reportDetails.specialistSummary) {
        reportDetails.specialistSummary = {
          summary: '',
          status: 'SAVED',
          updatedAt: null,
          updatedBy: null
        }
      }

      return reportDetails
    },

    displaySuccessNotification(transition) {
      let successMessage = ''
      switch (transition) {
        case 'saveSummaryDraft':
          successMessage = 'Expert summary draft saved.'
          break
        case 'submitSummary':
          successMessage = 'Expert summary submitted.'
          break
        case 'readyReport':
          successMessage = 'Report marked as ready for approval.'
          break
        case 'approveReport':
          successMessage = 'Report approved.'
          break
        case 'withdrawSummaryBeforeMarkedReady':
          successMessage = 'Expert summary submission withdrawn.'
          break
        case 'withdrawSummaryBeforeApproval':
          successMessage = 'Ready for approval submission withdrawn.'
          break
        case 'saveClinicalNote':
          successMessage = 'Pathologist notes saved.'
          break
        case 'saveInternalNote':
          successMessage = 'Internal notes saved.'
          break
        default:
          console.warn('No success message defined for transition:', transition)
          successMessage = 'Action completed.'
          break
      }

      Notification({
        title: 'Success',
        text: successMessage,
        style: NOTIFICATION_STYLES.SUCCESS
      })
    },

    // The backend will determine the new state based on what it is supplied
    getChangesetBasedOnTransition(transition) {
      switch (transition) {
        case 'saveSummaryDraft':
          return {
            reportStatus: 'GENERATED',
            specialistSummary: this.fromEditorToSavedFormat(
              this.specialistSummaryFieldContent
            ),
            isSpecialistSummarySubmitted: false
          }
        case 'withdrawSummaryBeforeMarkedReady':
          return {
            reportStatus: 'GENERATED',
            specialistSummary: this.specialistSummary,
            isSpecialistSummarySubmitted: false
          }
        case 'withdrawSummaryBeforeApproval':
          return {
            reportStatus: 'GENERATED'
          }
        case 'submitSummary':
          return {
            reportStatus: 'GENERATED',
            specialistSummary: this.fromEditorToSavedFormat(
              this.specialistSummaryFieldContent
            ),
            isSpecialistSummarySubmitted: true
          }
        case 'saveClinicalNote':
          return {
            clinicalNotes: this.fromEditorToSavedFormat(
              this.clinicalNoteFieldContent
            )
          }
        case 'saveInternalNote':
          return {
            internalNotes: this.fromEditorToSavedFormat(
              this.internalNoteFieldContent
            )
          }
        case 'readyReport':
          return {
            reportStatus: 'READY_FOR_APPROVAL'
          }
        case 'approveReport':
          return {
            reportStatus: 'APPROVED',
            clinicalNotes: this.clinicalNoteFieldContent
          }
        default:
          return {}
        }
    },

    resetFieldsBasedOnTransition(transition) {
      // fields to reset to their persisted values
      switch (transition) {
        case 'saveSummaryDraft':
        case 'submitSummary':
          this.specialistSummaryFieldContent = this.fromSavedToEditorFormat(
            this.getSpecialistSummary(this.reportDetails)
          )
          break
        case 'readyReport':
          this.specialistSummaryFieldContent = this.fromSavedToEditorFormat(
            this.getSpecialistSummary(this.reportDetails)
          )
          break
        case 'approveReport':
          this.specialistSummaryFieldContent = this.fromSavedToEditorFormat(
            this.getSpecialistSummary(this.reportDetails)
          )
          this.clinicalNoteFieldContent = this.fromSavedToEditorFormat(
            this.getClinicalNote(this.reportDetails)
          )
          break
        case 'withdrawSummaryBeforeMarkedReady':
          this.specialistSummaryFieldContent = this.fromSavedToEditorFormat(
            this.getSpecialistSummary(this.reportDetails)
          )
          break
        case 'withdrawSummaryBeforeApproval':
          this.fromSavedToEditorFormat(this.getMarkedReadyAtString(this.reportDetails)
          )
          break
        case 'saveClinicalNote':
          this.clinicalNoteFieldContent = this.fromSavedToEditorFormat(
            this.getClinicalNote(this.reportDetails)
          )
          break
        case 'saveInternalNote':
          this.internalNoteFieldContent = this.fromSavedToEditorFormat(
            this.getInternalNote(this.reportDetails)
          )
          break
        default:
          break
      }
    },

    handleSpecialistSummaryFieldContentChanged(updatedContent) {
      this.specialistSummaryFieldContent = updatedContent
    },

    handleClinicalNoteFieldContentChanged(updatedContent) {
      this.clinicalNoteFieldContent = updatedContent
    },

    handleInternalNoteFieldContentChanged(updatedContent) {
      this.internalNoteFieldContent = updatedContent
    },

    // This method removes trailing line breaks from the editor content
    // This is necessary to mask a bug present in the Quill editor staff-portal is currently using.
    // Trailing whitespace is not recognised by the editor cursor on load,
    // but becomes recognised once the editor is interacted with, leading to confusing UX.
    // (Also, the Quill editor adds new line breaks as `<p><br></p>`, just wtf?)
    removeTrailingEditorLineBreaks(content) {
      let updatedContent = content

      while (updatedContent.endsWith(badLineBreak)) {
        updatedContent = updatedContent.slice(0, -11)
      }

      return updatedContent
    },

    fromEditorToSavedFormat(content) {
      let updatedContent = content

      updatedContent = this.removeTrailingEditorLineBreaks(content)

      updatedContent = updatedContent.replace(
        badLineBreakPattern,
        goodLineBreak
      )

      return updatedContent
    },

    fromSavedToEditorFormat(content) {
      let updatedContent = content

      updatedContent = updatedContent.replace(
        goodLineBreakPattern,
        badLineBreak
      )

      return updatedContent
    },
    async handleGenerateDraft() {
      try {
        this.expertSummary.loading = true
        const response = await reportService.getDraftSummary(this.reportId)
        const { summary } = JSON.parse(response.data.body)
        this.specialistSummaryFieldContent = summary
      } catch (e) {
        Notification({
          title: 'Failed',
          text:  `An error occurred generating the summary: ${e.response.data}`,
          style: NOTIFICATION_STYLES.ERROR
        })
      } finally {
        this.expertSummary.loading = false
      }
    },
  }
}
</script>

<template>
  <div class="approval-notes">
    <!-- Referral Notes -->
    <div class="mb-5">
      <div class="notesTitle d-flex flex-row align-items-center">
        <font-awesome-icon
          :icon="['fa', 'fa-comment']"
          class="fa-2x noteIcon align-self-center"
        />
        <h3 class="title align-self-center">Referral Note from Practitioner</h3>
      </div>
      <note
        v-if="referralNote"
        :note="referralNote"
        :last-updated-string="referralNoteLastUpdatedString"
        :readonly="true"
      />
      <p v-else class="m-1">No referral note available.</p>
    </div>

    <!-- Expert Summary -->
    <div class="mb-5">
      <div class="notesTitle d-flex flex-row align-items-center">
        <font-awesome-icon
          :icon="['fa', 'fa-compass']"
          class="fa-2x noteIcon align-self-center"
        />
        <h3 class="title align-self-center">Expert Summary</h3>
      </div>
      <div class="noteDetails">
        <small
          >These notes will be displayed on the report for the
          practitioner.</small
        >
      </div>
      <div class="mt-2 mb-2">
        <button 
          v-if="canPerformAction('submitSummary') && !readonly"
          class="generate-btn mb-2" :disabled="expertSummary.loading" @click="handleGenerateDraft">
            Generate a draft summary
            <font-awesome-icon v-if="expertSummary.loading" :icon="['fa', 'fa-spinner']" class="fa-spin fa-fw" />
            <font-awesome-icon v-else :icon="['fa-regular', 'fa-wand-magic-sparkles']" class="fa align-self-center" />
        </button>
      </div>
      <note
        :note="specialistSummaryFieldContent"
        :last-updated-string="specialistSummaryLastUpdatedString"
        :readonly="readonly || !canPerformAction('editSummary')"
        @notes-updated="handleSpecialistSummaryFieldContentChanged($event)"
      />
      <div class="noteEditorControls mb-5" v-if="!readonly">
        <button
          class="btn btn-sm btn-secondary ml-3"
          @click="handleTransition('saveSummaryDraft')"
          :disabled="
            specialistSummaryFieldContent ===
              fromSavedToEditorFormat(specialistSummary) ||
            !canPerformAction('saveSummaryDraft')
          "
        >
          {{
            currentTransition === 'saveSummaryDraft'
              ? 'Loading'
              : 'Save Draft'
          }}
        </button>
        <button
          class="btn btn-sm btn-primary ml-3"
          @click="handleTransition('submitSummary')"
          :disabled="!canPerformAction('submitSummary')"
        >
          Save and Submit
        </button>
      </div>
    </div>

    <!-- Review by Lab Director (mark as ready) -->
    <div v-if="!readonly" class="mb-5">
      <div class="notesTitle d-flex flex-row align-items-center">
        <font-awesome-icon
          :icon="['fa', 'fa-star']"
          class="fa-2x noteIcon align-self-center"
        />
        <h3 class="title align-self-center">Review by Lab Director</h3>
      </div>
      <p v-if="markedReadyAtString" class="m-1">
        {{ markedReadyAtString }}
      </p>
      <div class="noteEditorControls">
        <button
          class="btn btn-sm btn-secondary ml-3"
          @click="handleTransition('withdrawSummaryBeforeMarkedReady')"
          :disabled="!canPerformAction('withdrawSummaryBeforeMarkedReady')"
        >
          {{
            currentTransition === 'withdrawSummaryBeforeMarkedReady'
              ? 'Loading'
              : 'Rollback to Expert Summary'
          }}
        </button>
        <button
          class="btn btn-sm btn-primary ml-3"
          @click="handleTransition('readyReport')"
          :disabled="!canPerformAction('readyReport')"
        >
          {{
            currentTransition === 'readyReport'
              ? 'Loading'
              : 'Ready for Pathologist'
          }}
        </button>
      </div>
    </div>

    <!-- Clinical Notes -->
    <div class="mb-5">
      <div class="notesTitle">
        <div class="d-flex flex-row align-items-center">
          <font-awesome-icon
            :icon="['fa', 'fa-certificate']"
            class="fa-2x noteIcon align-self-center"
          />
          <h3 class="title align-self-center">
            Report Notes from Supervising Microbiologist
          </h3>
        </div>
        <div class="noteDetails">
          <small
            >These notes will be displayed on the report for the
            practitioner.</small
          >
        </div>
      </div>
      <note
        :note="clinicalNoteFieldContent"
        :last-updated-string="clinicalNoteLastUpdatedString"
        @notes-updated="handleClinicalNoteFieldContentChanged($event)"
        :readonly="readonly || !canPerformAction('editClinicalNote')"
      />
      <div class="noteEditorControls" v-if="!readonly">
        <button
          class="btn btn-sm btn-secondary ml-3"
          @click="handleTransition('withdrawSummaryBeforeApproval')"
          :disabled="!canPerformAction('withdrawSummaryBeforeApproval')"
        >
          {{
            currentTransition === 'withdrawSummaryBeforeApproval'
              ? 'Loading'
              : 'Rollback to Lab Director'
          }}
        </button>
        <button
          class="btn btn-sm btn-secondary ml-3"
          @click="handleTransition('saveClinicalNote')"
          :disabled="
            clinicalNoteFieldContent ===
              fromSavedToEditorFormat(clinicalNote) ||
            !canPerformAction('saveClinicalNote')
          "
        >
          {{
            currentTransition === 'saveClinicalNote'
              ? 'Loading'
              : 'Save Draft'
          }}
        </button>
        <button
          class="btn btn-sm btn-primary ml-3"
          @click="handleTransition('approveReport')"
          :disabled="!canPerformAction('approveReport')"
        >
          {{
            currentTransition === 'approveReport'
              ? 'Loading'
              : 'Pathologist Approval'
          }}
        </button>
      </div>
    </div>

    <!-- Internal Notes -->
    <div>
      <div class="notesTitle">
        <div class="d-flex flex-row align-items-center">
          <font-awesome-icon
            :icon="['fa', 'fa-folder-plus']"
            class="fa-2x noteIcon align-self-center"
          />
          <h3 class="title align-self-center">Notes for Microba</h3>
        </div>
        <div class="noteDetails">
          <small>These notes will <b>not</b> be displayed on the report.</small>
        </div>
      </div>
      <note
        :note="internalNoteFieldContent"
        :last-updated-string="internalNoteLastUpdatedString"
        @notes-updated="handleInternalNoteFieldContentChanged($event)"
        :readonly="readonly || !canPerformAction('editInternalNote')"
      />
      <div class="noteEditorControls" v-if="!readonly">
        <button
          class="btn btn-sm btn-secondary ml-3"
          @click="handleTransition('saveInternalNote')"
          :disabled="
            internalNoteFieldContent ===
              fromSavedToEditorFormat(internalNote) ||
            !canPerformAction('saveInternalNote')
          "
        >
          {{
            currentTransition === 'saveInternalNote'
              ? 'Loading'
              : 'Save Draft'
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.noteDetails {
  color: #555;
}

.approval-notes {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .notesTitle {
    color: var(--color-secondary-100);
  }

  h3 {
    color: var(--color-secondary-100);
    padding-left: 16px;
    padding-top: 16px;
  }

  .quill-editor {
    margin: 0px 20px;
    min-height: 200px;
    .ql-container {
      .ql-editor {
        min-height: 200px;
        p {
          font-size: 14px;
          font-weight: 700;
          line-height: 140%;
          color: #021b30;
          mix-blend-mode: normal;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
      }
    }
  }

  .noteEditorControls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    margin-right: 20px;

    .btn {
      border: none;
      padding: 5px 15px;
      font-size: 14px;
      transition: background-color 0.3s ease;
      margin-left: 10px;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        cursor: not-allowed;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba(95, 109, 179, 0.25);
      }

      &.btn-primary {
        background-color: var(--color-secondary-100);
        color: white;

        &:hover {
          background-color: var(--color-secondary-darkest);
        }

        &:disabled {
          background-color: #ccc;
        }
      }

      &.btn-secondary {
        background-color: var(--color-secondary-20);
        color: var(--color-secondary-100);

        &:hover {
          background-color: var(--color-secondary-40);
        }

        &:disabled {
          background-color: #ccc;
          color: white;
        }
      }
    }
  }
}

.generate-btn {
  font-size: 14px;
  padding: 5px 15px;
  border: 1px solid var(--color-secondary-100);
  color: var(--color-secondary-100);
  font-weight: bolder;
  background: none;
  border-radius: 4px;

  &:hover {
    filter: brightness(0.8);
  }

  &:disabled {
    opacity: 0.75;
  }
}
</style>
