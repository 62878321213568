const reportStatus = Object.freeze({
  GENERATED: 'GENERATED',
  EXPERT_SUMMARY_SUBMITTED: 'EXPERT_SUMMARY_SUBMITTED',
  READY_FOR_APPROVAL: 'READY_FOR_APPROVAL',
  APPROVED: 'APPROVED',
  RECALLED: 'RECALLED',
  RELEASED: 'RELEASED'
})

export { reportStatus }
