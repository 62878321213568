<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: 'RichEditor',
  components: {
    quillEditor
  },
  props: {
    inboundContent: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }]
          ]
        }
      }
    }
  },
  methods: {
    triggerContentChangedEvent(payload) {
      this.$emit('editorContentChanged', payload.html)
    }
  }
}
</script>

<template>
  <quill-editor
    ref="quillEditor"
    :content="inboundContent"
    :options="editorOption"
    @change="triggerContentChangedEvent"
  />
</template>
