import moment from 'moment'
import store from '@/stores/index.js'
import {
  Confirm,
  TokenService,
  AuthService,
  USER_TYPE
} from '@microbadevs/library'
import { APP_AUTH_MSG } from '@/page/utils'

const TOKEN_EVENTS = {
  REFRESH_TOKEN_EXPIRY_NOTICE: 'REFRESH_TOKEN_EXPIRY_NOTICE',
  ACCESS_TOKEN_EXPIRY_NOTICE: 'ACCESS_TOKEN_EXPIRY_NOTICE'
}
const alertNSecondsBeforeRefreshTokenExpire = 1800
const checkEveryNSeconds = 60
const ignore2Fa = localStorage.getItem('ignore_2fa')

export function responseErrorHandler (error) {
  if (error.response?.status === 503) {
    store.dispatch('maintenance/show', error.response?.data)
  }
}

const authService = {
  async isUserEnabling2FA() {
    const { accessToken, refreshToken } = TokenService.getTokenDetails()
    const authService = new AuthService({ accessToken, refreshToken, responseErrorHandler })
    const is2FAEnabled = await authService.is2FAEnabled(USER_TYPE.USER)

    if (ignore2Fa) {
      return false
    }

    if (process.env.VUE_APP_IS_MFA_ENABLED === 'true' && !is2FAEnabled) {
      return true
    }

    return false
  },

  is2FARequired(error) {
      return error?.response?.data?.error_code === 'mfa_verification_required';
  },

  async authIframe(iframeWindow) {
    const appAuth = {
      access_token: this.getAccessToken(),
      authorities: this.getRoles(),
      email: this.getUserEmail(),
      expires_in: this.getAccessTokenExpiration() * 1000,
      logged_in: this.isLoggedIn(),
      refresh_token: this.getRefreshToken(),
    }

    iframeWindow?.postMessage(
      {
        type: APP_AUTH_MSG,
        app_auth: appAuth
      },
      '*'
    )
  },

  initialize(accessToken, refreshToken) {
    new TokenService(accessToken, refreshToken)

    addEventListener(
      TOKEN_EVENTS.REFRESH_TOKEN_EXPIRY_NOTICE,
      showRefreshTokenExpiryNotice
    )
    addEventListener(
      TOKEN_EVENTS.ACCESS_TOKEN_EXPIRY_NOTICE,
      triggerAccessTokenRenewal
    )
  },

  getUserID() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.userId
  },

  getUserEmail() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.userEmail
  },

  getUsername() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.userName
  },

  getRoles() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.roles
  },

  getAccessToken() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.accessToken
  },

  getAccessTokenExpiration() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.accessTokenExpiry
  },

  getRefreshToken() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.refreshToken
  },

  tokenHasExpired() {
    const expiry = authService.getAccessTokenExpiration()
    return expiry && moment().unix() > expiry
  },

  isLoggedIn() {
    return !!authService.getAccessToken() && !this.tokenHasExpired()
  },

  logout() {
    TokenService.clearTokenDetails()
  },

  /**
  * Attempt to renew the user tokens from a refresh token.
  *
  * @param {*} refreshToken optional refresh token to renew from
  */
  async getTokenDataFromRefreshToken(refreshToken){
   try {
     // check the token is correct
     if(typeof refreshToken !== 'string') {
       throw Error("Provided refresh token is not a string.")
     }
     // dispatch the refresh action
     const {data} = await store.dispatch('refreshAccessToken', { refreshToken })

     return data;
   } catch (error) {
    console.warn("Unable to refresh token: ", error)
    return null;
   }
 }

}

function triggerAccessTokenRenewal() {
  store
    .dispatch('refreshAccessToken', {
      refreshToken: authService.getRefreshToken()
    })
    .then(({ data }) => {
      TokenService.updateTokenDetails(data?.access_token, data?.refresh_token)
    })
}

function showRefreshTokenExpiryNotice() {
  Confirm({
    title: 'Session is about to expire',
    text: `Your session is about to expire in next ${
      alertNSecondsBeforeRefreshTokenExpire / 60
    } minutes, do you want to start a new session?</b>`,
    type: 'warning',
    confirmButtonText: 'Yes Re-login'
  }).then((result) => {
    if (!result.value) {
      return
    }
    authService.logout()
    window.location.reload(false)
  })
}

export default authService
