<template>
  <div class="pagination-container">
    <button
      class="pagination-btn"
      :disabled="currentPage === 1"
      @click="changePage(currentPage - 1)"
    >
      <slot name="prev-icon">
        <!-- Default Previous Icon -->
        <font-awesome-icon :icon="['fas', 'less-than']" />
      </slot>

    </button>
    <button
      class="pagination-btn"
      :disabled="currentPage === totalPages"
      @click="changePage(currentPage + 1)"
    >
      <slot name="next-icon">
        <!-- Default Next Icon -->
        <font-awesome-icon :icon="['fas', 'greater-than']" />
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "SimplePagination",
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    }
  },
  methods: {
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.$emit("update:currentPage", page);
    },
  },
};
</script>

<style scoped>
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-family: Arial, sans-serif;
}

.pagination-btn{
  color: #333;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.pagination-btn:hover{
  color: #ff8708;
}

.pagination-btn:disabled {
  cursor: not-allowed;
  color: #ccc;
}
</style>

