<script>
import dateMixin from '@/mixins/date'
import RichEditor from '@/components/richEditor'
export default {
  name: 'Note',
  components: { RichEditor },
  mixins: [dateMixin],
  props: {
    type: { default: '', type: String },
    note: { default: null, type: String },
    lastUpdatedString: { default: '', type: String },
    readonly: { default: false, type: Boolean }
  },
  methods: {
    updateNote(payload) {
      this.$emit('notes-updated', payload)
    }
  }
}
</script>
<template>
  <div>
    <!-- Display Note Editor -->
    <div class="noteEditor mt-2">
      <template v-if="readonly">
        <div class="disabled-note" v-html="note"></div>
      </template>

      <template v-else>
        <rich-editor
          :inbound-content="note"
          @editorContentChanged="updateNote"
        />
      </template>
    </div>

    <p v-if="lastUpdatedString" class="m-1">
      {{ lastUpdatedString }}
    </p>
  </div>
</template>
<style lang="scss">
.disabled-note {
  padding: 10px;
  border: 1px solid #ccc;
  background: #eeeeee;
  margin: 0;
  min-height: 200px;
  p {
    margin: 0;
  }
}
.noteEditor {
  min-height: 158px;
}

.noteEditor > .quill-editor strong,
.noteEditor > .disabled-note strong {
  font-weight: 700 !important;
}

.ql-container.ql-snow {
  min-height: 158px;
}
</style>
