import { reportStatus } from '@/enums/reportStatus'
import BaseService from '@/services/baseService'

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export default class PodiumMetaXploreReportService extends BaseService {

  static async getReportsByReferrerEmail(
    states = [reportStatus.GENERATED],
    noOfReports = 100,
    page = 1,
    sortBy = 'id',
    sortDirection = 'desc',
    email = ''
  ) {
    let allReferrals = []
    let currentPage = 0
    let hasMorePages = true

    while (hasMorePages) {
      const response = await super.callStore('getReferralsByPractitionerEmail', {
        practitionerEmail: email,
        page: currentPage,
        size: noOfReports
      })

      const referrals = response.data.content
      allReferrals = allReferrals.concat(referrals)

      hasMorePages = !response.data.last
      currentPage++
    }

    // If no referrals found, return empty result set instead of still requesting Podium
    if (allReferrals.length === 0) {
      return {
        content: [],
        empty: true,
        first: true,
        last: true,
        number: 0,
        numberOfElements: 0,
        size: noOfReports,
        totalElements: 0,
        totalPages: 0
      }
    }

    // Extract referral IDs (referral references) from collected referrals
    const referralReferences = allReferrals.map(referral => referral.id)

    const reportsResponse = await super.callStore('getReportsFromPodium', {
      states,
      noOfReports,
      page,
      sortBy,
      sortDirection,
      keyword: '',
      referralReferences
    })

    reportsResponse.data.content = reportsResponse.data.content.map(report =>
      ({
        ...report,
        practitioner: email
      }))

    return reportsResponse.data
  }

  static async getReports(
    states = [reportStatus.GENERATED],
    noOfReports = 100,
    products,
    page = 1,
    sortBy = 'id',
    sortDirection = 'DESC',
    keyword = ''
  ) {
    if (emailPattern.test(keyword)) {
      return PodiumMetaXploreReportService.getReportsByReferrerEmail(
        states,
        noOfReports,
        page,
        sortBy,
        sortDirection,
        keyword
      )
    } else {
      return PodiumMetaXploreReportService.getReportsAndReferrerEmails(
        states,
        noOfReports,
        products,
        page,
        sortBy,
        sortDirection,
        keyword
      )
    }
  }

  static async getReportsAndReferrerEmails(
    states = [reportStatus.GENERATED],
    noOfReports = 100,
    products = ['MetaXplore'],
    page = 1,
    sortBy = 'id',
    sortDirection = 'desc',
    keyword = '',
    referralReferences = []
  ) {
    const reportsResponse = await super.callStore('getReportsFromPodium', {
      states,
      noOfReports,
      products,
      page,
      sortBy,
      sortDirection,
      keyword,
      referralReferences
    }
    )

    const reports = reportsResponse.data.content

    const referralIds = reports.map(report => report.referralReference)

    const allReferrals = await PodiumMetaXploreReportService.getAllReferrals(referralIds, noOfReports)

    const referralsMap = new Map(allReferrals.map(referral => [referral.id, referral]))

    reportsResponse.data.content = reports.map(report => ({
      ...report,
      practitioner: referralsMap.get(report.referralReference)?.practitioner?.emailAddress
    }))


    return reportsResponse.data
  }

  // TODO: evaluate for refactoring once HCP is merged in Nucleus. We probably don't need to fetch all referrals but the ones for a page.
  static async getAllReferrals(referralIds, size) {
    let allReferrals = []
    let page = 0
    let hasMorePages = true
    let request = {
      ids: referralIds
    }

    while (hasMorePages) {
      const referralsResponse = await super.callStore('getReferralsByIds', {
        request,
        size,
        page
      })

      const referrals = referralsResponse.data.content
      allReferrals = allReferrals.concat(referrals)

      hasMorePages = referralsResponse.data.last === false
      page++
    }

    return allReferrals
  }
}
