<script>
import { Notification, NOTIFICATION_STYLES, Loader } from '@microbadevs/library'
import notes from '@/components/report/notes'
import dateMixin from '@/mixins/date'
import reportService from '@/services/reportService'
import { reportStatus } from '@/enums/reportStatus'
import DropdownWithTags from '@/page/components/DropdownWithTags.vue'
import SimplePagination from '@/components/elements/SimplePagination.vue'
import PodiumMetaXploreReportService from '@/services/podiumMetaXploreReportService'


const DEFAULT_STATUSES = [
  'Ready for Summary',
  'Summary Submitted',
  'Ready for Approval',
  'Approved'
]

const DISPLAY_STATE_MAPPING = Object.freeze({
  'GENERATED': 'Ready for Summary',
  'EXPERT_SUMMARY_SUBMITTED': 'Summary Submitted',
  'READY_FOR_APPROVAL': 'Ready for Approval',
  'APPROVED': 'Approved',
  'RELEASED': 'Released',
  'RECALLED': 'Recalled'
})

export default {
  name: 'PodiumCobiomeResultRelease',
  components: { SimplePagination, notes, DropdownWithTags },
  mixins: [dateMixin],
  data() {
    return {
      reports: [],
      fields: [
        {
          key: 'reportId',
          sortable: true
        },
        {
          key: 'sampleId',
          label: 'Sample',
          sortable: true
        },
        {
          key: 'practitioner',
          label: 'Practitioner Email',
          sortable: false
        },
        {
          key: 'reportStatus',
          sortable: true
        },
        {
          key: 'approved'
        },
        {
          label: 'Product',
          key: 'productName',
          sortable: true
        },
        {
          key: 'action'
        }
      ],
      selectedReports: [],
      selectedStatuses: this.loadSelectedFilterStatuses(DEFAULT_STATUSES),
      products: ['MetaXplore','MetaXplore GI Plus', 'MetaXplore GI'],
      query: '',
      isFiltered: false,
      displayNotes: false,
      activeReport: null,
      noteEditorVisible: false,
      page: 1,
      perPage: 50,
      sortBy: null,
      sortDesc: true,
      totalElements: 0,
      totalPages: 1,
      localSortingDisabled: true,
      isBusy: false
    }
  },
  computed: {
    allReportStatuses() {
      return reportStatus
    },
    areAllReportsInPageSelected() {
      return (
        this.selectedReports.length === this.reports.length &&
        this.reports.length > 0
      )
    },
    areSomeReportsInPageSelected() {
      return (
        this.selectedReports.length > 0 &&
        this.selectedReports.length < this.reports.length
      )
    },
    releaseButtonClass() {
      return this.selectedReports.length
        ? 'release-button-selected'
        : 'release-button-disabled'
    },
    selectAllText() {
      return this.areSomeReportsInPageSelected
        ? `${this.selectedReports.length} Selected`
        : `Select All (${this.reports.length})`
    },
    paginationInfo() {
      const start = (this.page - 1) * this.perPage + 1;
      const end = Math.min(this.page * this.perPage, this.totalElements);
      return `${start}-${end} of ${this.totalElements}`;
    },
  },
  mounted() {
    this.getReports()
  },
  watch: {
    page: function () {
      this.getReports()
    }
  },
  methods: {
    async getReports() {
      try {
        Loader.show()
        const pagedReports = await PodiumMetaXploreReportService.getReports(
          this.mapToReportStates(this.selectedStatuses),
          this.perPage,
          this.products,
          this.page - 1,
          this.sortBy,
          this.sortDesc ? 'DESC' : 'ASC',
          this.query
        )

        this.isFiltered = this.query !== ''
        this.reports = pagedReports.content
        this.totalPages = pagedReports.totalPages
        this.totalElements = pagedReports.totalElements
        this.perPage = pagedReports.size
        this.page = pagedReports.number + 1
        this.isBusy = false

        this.updateStatusFilter()

        Loader.hide()
      } catch (error) {
        Notification({
          title: 'Failed',
          text: error.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    mapToReportStates(selectedStates) {
      if (!selectedStates || selectedStates.length === 0) {
        return ['GENERATED']
      }

      const reverseMapping = Object.entries(DISPLAY_STATE_MAPPING).reduce((map, [key, value]) => {
        map[value] = key
        return map
      }, {})

      return selectedStates.map(state => reverseMapping[state]).filter(Boolean)
    },

    getDisplayState(backendState) {
      return DISPLAY_STATE_MAPPING[backendState] || 'Error'
    },
    getReportStatusClass(status) {
      switch (status) {
      case reportStatus.GENERATED:
        return 'generated'
      case reportStatus.EXPERT_SUMMARY_SUBMITTED:
        return 'summary-submitted'
      case reportStatus.READY_FOR_APPROVAL:
        return 'ready-for-approval'
      case reportStatus.APPROVED:
        return 'approved'
      case reportStatus.RECALLED:
        return 'released'
      case reportStatus.RELEASED:
        return 'recalled'
      default:
        return 'invalid'
      }
    },
    getAllStatesDisplayValues() {
      const uniqueStatusValues = new Set(
        Object.values(DISPLAY_STATE_MAPPING)
      )
      return Array.from(uniqueStatusValues)
    },
    getReportLink(ReportData) {
      const { reportId } = ReportData

      if (!reportId) {
        Notification({
          text: 'Failed to fetch report details.',
          style: NOTIFICATION_STYLES.ERROR
        })
        return
      }

      const reportLink = `/cobiome-report-view/${reportId}`
      return reportLink
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    onRowSelected(items) {
      this.selectedReports = items
    },
    previewSelectedReports() {
      this.selectedReports.forEach((report, index) => {
        const reportLink = this.getReportLink(report)
        window.open(reportLink, `report_${index}`)
      })
    },
    isReportSelected(reportId) {
      return Boolean(
        this.selectedReports.find((report) => report.reportId === reportId)
      )
    },
    toggleReportSelection(report, rowIndex) {
      const { reportId } = report
      if (this.isReportSelected(reportId)) {
        this.selectedReports = this.selectedReports.filter(
          (report) => report.reportId !== reportId
        )
        this.$refs.selectableTable.unselectRow(rowIndex)
      } else {
        this.selectedReports.push(report)
        this.$refs.selectableTable.selectRow(rowIndex)
      }
    },
    async releaseSelectedReports() {
      try {
        const {
          isConfirmed: releaseSelectedReportResponse
        } = await Notification({
          title: 'Release the results for selected reports?',
          text: 'Are you sure',
          style: NOTIFICATION_STYLES.QUESTION
        })

        if (releaseSelectedReportResponse) {
          Loader.show()
          for (const report of this.selectedReports) {
            await reportService.updateReport(report.reportId, {
              reportStatus: reportStatus.RELEASED
            })
          }
          Loader.hide()
          await Notification({
            title: 'Success',
            text: 'Report released.',
            style: NOTIFICATION_STYLES.SUCCESS
          })
          await this.getReports()
        }
      } catch (error) {
        Notification({
          title: 'Failed',
          text: error.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    async releaseReport(report) {
      try {
        const { reportId } = report
        const {
          isConfirmed: releaseSelectedReportResponse
        } = await Notification({
          title: `Release the results for Report ID ${reportId}?`,
          text: `Are you sure?`,
          style: NOTIFICATION_STYLES.QUESTION
        })
        if (releaseSelectedReportResponse) {
          Loader.show()
          await reportService.updateReport(report.reportId, {
            reportStatus: reportStatus.RELEASED
          })
          Loader.hide()
          await Notification({
            title: 'Success',
            text: 'Report released.',
            style: NOTIFICATION_STYLES.SUCCESS
          })
          await this.getReports()
        }
      } catch (error) {
        Notification({
          title: 'Failed',
          text: error.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    displayNotesView(report) {
      this.displayNotes = false
      this.activeReport = report
      this.displayNotes = true
    },
    sortingChanged({ sortBy, sortDesc }) {
      this.sortBy = sortBy
      this.sortDesc = sortDesc
      this.getReports()
    },
    updateStatusFilter() {
      this.saveSelectedFilterStatuses()
      const defaultStatusesStr = JSON.stringify([
        'Approved',
        'Ready for Approval',
        'Ready for Summary',
        'Summary Submitted'
      ])
      const selectedStr = JSON.stringify(this.selectedStatuses.sort())
      if (selectedStr !== defaultStatusesStr) this.isFiltered = true
    },
    resetQueryAndStatuses() {
      this.query = ''
      this.selectedStatuses = [...DEFAULT_STATUSES]
      this.getReports()
    },
    loadSelectedFilterStatuses(defaultStatuses) {
      const serializedStatuses = localStorage.getItem(
        'selectedReportFilterStatuses'
      )
      if (serializedStatuses) {
        return JSON.parse(serializedStatuses)
      }
      return [...defaultStatuses]
    },
    saveSelectedFilterStatuses() {
      const serializedStatuses = JSON.stringify(this.selectedStatuses)
      localStorage.setItem('selectedReportFilterStatuses', serializedStatuses)
    },
    toggleSelectAllReports(checked) {
      this.selectedReports = checked ? [...this.reports] : []
    },
  }
}
</script>
<template>
  <div class="page container-fluid">
    <transition name="slide-fade">
      <div>
        <header class="inline-between inline-wrap">
          <h1 class="page-header">Co-Biome Results Release</h1>
        </header>
        <transition name="slide-fade">
          <div class="box box-shadow stack">
            <div class="inline-zero form-inline">
              <b-form-input
                v-model="query"
                type="search"
                placeholder="Search Report ID, Sample ID, Practitioner Email"
                autofocus
                @keyup.enter="getReports"
              />
              <dropdown-with-tags
                class="col-md-2"
                v-model="selectedStatuses"
                :availableValues="getAllStatesDisplayValues()"
                button-text="Status"
                @value-changed="getReports"
              />
              <button class="btn btn-primary" @click="getReports">Go</button>
            </div>
            <div v-if="isFiltered">
              <button
                @click="resetQueryAndStatuses"
                class="btn btn-sm btn-link"
              >
                Reset filter
              </button>
            </div>
            <div class="actions-container">
              <div class="bulk-actions">
                <div class="select-all">
                  <b-form-checkbox
                    :checked="areAllReportsInPageSelected"
                    :indeterminate="areSomeReportsInPageSelected"
                    class="report-release-checkbox"
                    @change="toggleSelectAllReports"
                  >
                    {{ selectAllText }}
                  </b-form-checkbox>
                </div>
                <b-button
                  :disabled="!selectedReports.length"
                  class="release-button"
                  :class="releaseButtonClass"
                  @click="releaseSelectedReports"
                >
                  Release Reports
                </b-button>
              </div>
              <div v-if="totalPages > 1" class="pagination-container">
                <span class="pagination-info">{{ paginationInfo }}</span>
                <SimplePagination
                  :total-items="totalElements"
                  :items-per-page="perPage"
                  :current-page.sync="page"
                  @update:currentPage="getReports"
                />
              </div>
            </div>
            <b-table
              id="reports-table"
              ref="selectableTable"
              striped
              :items="reports"
              :fields="fields"
              selectable
              show-empty
              :no-local-sorting="localSortingDisabled"
              :foot-clone="reports.length > 20"
              @sort-changed="sortingChanged"
              @row-selected="onRowSelected"
              sticky-header="700px"
            >
              <template v-slot:cell(reportId)="row">
                <div class="inline-zero">
                  <b-form-checkbox
                    class="report-checkbox"
                    :checked="isReportSelected(row.item.reportId)"
                    @change="toggleReportSelection(row.item, row.index)"
                  >
                  </b-form-checkbox>

                  <router-link
                    :to="{
                      name: 'Cobiome Report Preview',
                      params: { reportId: row.item.reportId }
                    }"
                  >{{ row.item.reportId }}
                  </router-link>
                </div>
              </template>
              <template v-slot:cell(approved)="row">
                <div
                  v-if="row.item.reportStatus === allReportStatuses.APPROVED"
                  class="inline-half"
                >
                  <font-awesome-icon :icon="['fa', 'fa-check-circle']" />
                  {{ row.item.approvedAt | formatDate('D MMM YYYY, h:mma') }}
                </div>
              </template>
              <template v-slot:cell(reportStatus)="row">
                <p
                  :class="[
                    'tag',
                    getReportStatusClass(
                      row.item.reportStatus,
                    )
                  ]"
                >
                  {{
                    getDisplayState(row.item.reportStatus)
                  }}
                </p>
              </template>
              <template v-slot:cell(action)="row">
                <div class="inline-half inline-wrap">
                  <router-link
                    class="btn btn-sm btn-outline-warning inline-half"
                    :to="{
                      name: 'Cobiome Report Preview',
                      params: { reportId: row.item.reportId }
                    }"
                  >
                    View Report
                  </router-link>
                  <button
                    class="btn btn-sm btn-outline-warning inline-half"
                    @click="displayNotesView(row.item)"
                  >
                    <font-awesome-icon :icon="['far', 'fa-sticky-note']" />
                    View Notes
                  </button>
                  <button
                    class="font-weight-bold btn-sm btn btn-outline-danger inline-half"
                    @click="releaseReport(row.item)"
                  >
                    <font-awesome-icon :icon="['fa', 'fa-check']" />
                    Release Report
                  </button>
                </div>
              </template>
            </b-table>
          </div>
        </transition>
      </div>
    </transition>
    <b-sidebar
      id="report-notes"
      aria-controls="report-notes"
      :visible="displayNotes"
      bg-variant="white"
      width="400px"
      right
      shadow
      no-header
      @change="displayNotes = $event"
    >
      <template #default="{ hide }">
        <div v-if="activeReport" class="p-4">
          <h2 id="report-notes-no-header-title">
            Report ID {{ activeReport.reportId || '' }}
            <font-awesome-icon
              :icon="['fa', 'fa-times-circle']"
              class="float-right"
              @click="hide"
            />
          </h2>

          <p>
            Report was generated on the
            <span class="text-muted font-weight-bold"
            >{{ activeReport.generatedAt | formatDate('Do MMM YYYY') }}.</span
            >
          </p>
          <notes
            :key="activeReport.reportId"
            :report-id="activeReport.reportId"
            :readonly="true"
            @reportDetailsChanged="getReports()"
          />
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<style scoped lang="scss">
@import '@/styles/reports/_metaxplore.scss';
</style>

<style scoped>
.template-page main header {
  margin: 0;
  padding: 1.5rem 0;
}

header > *,
.template-page main header .page-header {
  margin: 0 !important;
}

.box {
  overflow: auto;
}

td a {
  color: inherit;
}

td > .badge {
  font-size: 14px;
  width: fit-content;
}

.btn {
  white-space: nowrap;
}

.fa-check-circle {
  color: #5cd69f;
  font-size: 1.2rem;
}

.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.bulk-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: white;
}

.pagination-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select-all {
  display: flex;
  align-items: center;
  width: 120px;
}

.release-button {
  border: none;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
}

.pagination-info {
  margin-right: 40px;
}

.release-button-disabled {
  background-color: #e7e7e7;
  color: black;
  cursor: not-allowed;
}

.release-button-selected {
  background-color: #e4e7f2;
  color: #4e5ca4;
  cursor: pointer;
}

/* The following styles are hacks to overwrite the custom-bootstrap theme colour. */
.report-release-checkbox >>> .custom-control-label:before,
.report-checkbox >>> .custom-control-label:before {
  background-color: white;
  border-radius: 3px;
}

.report-release-checkbox
>>> .custom-control-input:checked
+ .custom-control-label:before,
.report-checkbox
>>> .custom-control-input:checked
+ .custom-control-label:before {
  background-color: #6e7bba;
  border-color: #6e7bba;
}

.report-release-checkbox
>>> .custom-control-input:indeterminate
+ .custom-control-label:before,
.report-checkbox
>>> .custom-control-input:indeterminate
+ .custom-control-label:before {
  background-color: #6e7bba;
  border-color: #6e7bba;
}
</style>
