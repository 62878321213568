<template>
  <div class="dropdown-container" ref="dropdown">
    <button class="dropdown-button" @click="toggleDropdown">
      {{ buttonText }}
      <span class="arrow-down"></span>
    </button>
    <div v-if="isDropdownOpen" class="dropdown-menu">
      <div
        class="dropdown-item"
        v-for="value in availableValues"
        :key="value"
        @click.prevent="toggleOption(value)"
        :class="{ active: updatedValues.includes(value) }"
      >
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownWithTags',

  props: {
    availableValues: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    buttonText: {
      type: String,
      default: 'Select'
    }
  },

  data() {
    return {
      isDropdownOpen: false,
      updatedValues: [...this.value],
      currentValues: [...this.value]
    }
  },

  watch: {
    value(newValue) {
      this.updatedValues = [...newValue]
    }
  },

  methods: {
    toggleDropdown() {
      if (
        this.isDropdownOpen &&
        JSON.stringify(this.updatedValues) !==
          JSON.stringify(this.currentValues)
      ) {
        this.$emit('value-changed', this.updatedValues)
      } else {
        this.currentValues = [...this.updatedValues]
      }
      this.isDropdownOpen = !this.isDropdownOpen
    },

    toggleOption(option) {
      const index = this.updatedValues.indexOf(option)
      if (index > -1) {
        this.updatedValues.splice(index, 1)
      } else {
        this.updatedValues.push(option)
      }
      this.$emit('input', this.updatedValues)
    },

    handleClickOutside(event) {
      const dropdown = this.$refs.dropdown
      if (dropdown && !dropdown.contains(event.target) && this.isDropdownOpen) {
        if (
          JSON.stringify(this.updatedValues) !==
          JSON.stringify(this.currentValues)
        ) {
          this.$emit('value-changed', this.updatedValues)
        }
        this.isDropdownOpen = false
      }
    }
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<style scoped>
.dropdown-container {
  position: relative;
  width: 100%;
}

.dropdown-button {
  width: 100%;
  padding: 11px 16px;
  background-color: #fff;
  color: var(--color-grey-40);
  border: 1px solid rgba(0, 0, 0, 0.15);
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--color-grey-40);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 8px;
}

.dropdown-item {
  border-radius: 40rem;
  font-size: 100%;
  font-weight: 700;
  letter-spacing: 0.03rem;
  line-height: 1;
  padding: var(--size-half) var(--size-base);
  text-transform: capitalize;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-secondary-20);
  color: var(--color-secondary-100);
  cursor: pointer;
}

.dropdown-item.active {
  background-color: var(--color-secondary-100);
  color: var(--color-secondary-20);
}
</style>
