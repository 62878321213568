import { reportStatus } from '@/enums/reportStatus'
import BaseService from '@/services/baseService'

export default class ReportService extends BaseService {
  static async getReports(
    status = [reportStatus.GENERATED],
    summaryStatuses,
    noOfReports = 100,
    product = 'metaxplore',
    page = 1,
    sortBy = 'id',
    sortDirection = 'desc',
    keyword = ''
  ) {
    return super.callStore('getReports', { status, summaryStatuses, noOfReports, product, page, sortBy, sortDirection, keyword })
  }

  static async getReport(id) {
    return super.getById(id, 'getReportById', { id })
  }

  static async updateReport(id, payload) {
    return super.updateById(id, 'updateReport', payload)
  }

  static async getDraftSummary(id) {
    return super.callStore('getDraftSummary', id)
  }
}
